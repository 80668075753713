import { Box, Button, Card, CardContent, CardHeader, List, Typography } from "@mui/material";
import { LocationListItem } from "./LocationListItem";
import { useFieldArray, useFormContext } from "react-hook-form";
import React, { useMemo, useState } from "react";
import { LocationDialog } from "../LocationDialog";
import { Container, Draggable } from "react-smooth-dnd";
import { capitalize } from "lodash";

const InitialDialogState = {
  isOpen: false,
  action: "none",
  indexToEdit: -1,
};

export const LocationSection = () => {
  const { control, watch, getValues } = useFormContext();

  const [dialogState, setDialogState] = useState(InitialDialogState);

  const isMultiLocationsAllowed = useMemo(() => {
    const mode = getValues("mode");
    return mode === "TL";
  }, [watch("mode")]);

  const {
    remove: removeLocation,
    update: updateLocation,
    move: moveLocation,
  } = useFieldArray({
    name: "locations",
    control,
  });

  const locs = watch("locations");

  const onDragEnd = ({ removedIndex, addedIndex }) => {
    moveLocation(removedIndex, addedIndex);
  };

  return (
    <Box>
      <Card
        variant="outlined"
        sx={{
          py: "1.5rem",
          px: "1rem",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
        }}
      >
        <CardHeader
          title="Locations"
          action={
            <Button
              type="button"
              size="small"
              disabled={locs?.length === 2 && !isMultiLocationsAllowed}
              onClick={() => setDialogState({
                isOpen: true,
                action: "create",
                indexToEdit: locs?.length ?? 0,
              })}
              sx={{ minWidth: "8rem", height: "2.5rem" }}
            >
              Add Location
            </Button>
          }
          sx={{
            "&.MuiCardHeader-root": { p: 0 },
          }}
        />
        <CardContent
          sx={{
            "&.MuiCardContent-root": { p: 0, py: "1rem" },
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "0.75rem",
          }}
        >
          <List>
            <Container
              dragHandleSelector=".drag-handle"
              lockAxis="y"
              onDrop={onDragEnd}
            >
              {!locs || !locs.length ?
                <Typography fontSize={"0.9rem"} color={"GrayText"}>
                  No Locations
                </Typography> :
                locs.map((location, index) => (
                  <Draggable key={index}>
                    <div className="drag-handle">
                      <LocationListItem
                        key={index}
                        location={location}
                        title={`${!location.name || !location.address1 ? "N/A" : location.name ?? location.address1} - ${capitalize(location.type ?? '')}`}
                        index={index}
                        onEditTapped={(indexToEdit) =>
                          setDialogState({
                            isOpen: true,
                            action: "update",
                            indexToEdit,
                          })
                        }
                        onRemoveTapped={(index) => removeLocation(index)}
                      />
                    </div>
                  </Draggable>
                ))}
            </Container>
          </List>
        </CardContent>
      </Card>
      <LocationDialog
        isOpen={dialogState.isOpen}
        isEdditing={dialogState.action !== 'create'}
        onClose={() => {
          if (dialogState.action === "create") {
            removeLocation(locs.length);
          }

          setDialogState(InitialDialogState);
        }}
        onUpdated={(index) => {
          const location = getValues(`locations.${index}`);
          updateLocation(index, location);
          setDialogState(InitialDialogState);
        }}
        itemIndex={dialogState.indexToEdit}
      />
    </Box >
  );
};
