import { RequestQuoteOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useAuth } from "src/hooks/useAuth";
import { fCurrency } from "src/utils/formatNumber";
import { useShipmentDetails } from "../../../context/ShipmentDetailsContext";
import { valueGetter } from "./DetailsDrawer";

export function QuotationsDetails() {
  const { quotations } = useShipmentDetails();

  return (
    <Box>
      <Grid container spacing={'1rem'}>
        {quotations.map((quotation) => <>
          <Grid item md={4}>
            <CarrierQuotation key={quotation.quoteId} quotation={quotation} />
          </Grid>
        </>
        )}
      </Grid>
    </Box>
  );
}

function CarrierQuotation({ quotation }) {
  const {
    shipment,
    confirmShipment,
    isConfirmationLoading,
    selectedQuotation,
    setSelectedQuotation,
  } = useShipmentDetails();

  const { session } = useAuth();

  const isBackOffice = session.permissions.IsBackOffice;
  const [isThisQuotationConfirmationLoading, setThisQuotationLoading] =
    useState(false);

  function handleShipmentConfirmation(quotation) {
    confirmShipment(shipment._id, quotation.loadId, quotation.quoteId, quotation.carrierName);
    setSelectedQuotation(quotation);
    setThisQuotationLoading(true);
  }

  const isSelectedQuotation = (quotation) =>
    selectedQuotation?.quoteId == quotation.quoteId;

  function isCarrierBlocked(carrierName) {
    const blockedCarriers = [];

    return blockedCarriers.includes(carrierName);
  }

  return (
    <Card
      variant="outlined"
      sx={{
        py: "0.5rem",
        px: "1rem",
        height: '100%',
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
      }}
    >
      <CardHeader
        sx={{
          "&.MuiCardHeader-root": { p: 0 },
          flexDirection: {
            xs: "column",
            md: "column",
            lg: "row",
          },
          justifyContent: "center",
          alignItems: "baseline",
          gap: "1rem",
        }}
        title={quotation.carrierName}
        subheader={"Carrier"}
      />
      <CardContent
        sx={{
          "&.MuiCardContent-root": { p: 0 },
          display: "flex",
          flexDirection: "column",
          gap: 2,
          // justifyContent: "space-between",
          // alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>Net Price</Typography>
          <Typography fontWeight={600} fontSize={"1.2rem"}>
            {fCurrency(quotation.netPrice)}
          </Typography>
        </Box>
        {isBackOffice && quotation.carrierNote && (
          <Box>
            <Typography fontWeight={600}>Carrier Note</Typography>
            {/* <Typography>{valueGetter(quotation.carrierNote)}</Typography> */}
            <div
              dangerouslySetInnerHTML={{ __html: quotation.carrierNote }}
            ></div>
          </Box>
        )}
        {
          <LoadingButton
            loading={
              isConfirmationLoading && isThisQuotationConfirmationLoading
            }
            loadingPosition="start"
            startIcon={<RequestQuoteOutlined />}
            onClick={() => handleShipmentConfirmation(quotation)}
            disabled={
              (!!selectedQuotation && shipment.integrated) ||
              isCarrierBlocked(quotation?.carrierName)
            }
            className={isSelectedQuotation(quotation) ? "selected" : ""}
            variant="text"
            color={
              !!selectedQuotation &&
                shipment.integrated &&
                isSelectedQuotation(quotation)
                ? "success"
                : "primary"
            }
            sx={{
              "&.Mui-disabled.selected": {
                color: (theme) => theme.palette.success.darker,
                border: (theme) => theme.palette.success.darker,
                opacity: 0.8,
              },
              fontSize: "1rem",
              display: "flex",
              width: "100%",
            }}
          >
            {isCarrierBlocked(quotation?.carrierName)
              ? "Blocked Carrier"
              : "Confirm Shipment Quotation"}
          </LoadingButton>
        }
      </CardContent>
    </Card>
  );
}
