import { capitalize } from "lodash";

export function getEquipmentFromShipment(shipment) {
    if (shipment.mode === 'LTL') return 'LTLVan';

    if (!shipment.equipment) return undefined;

    const tmp = shipment.equipment;    
    delete tmp._id;

    return capitalize(Object.keys(tmp).find(value => tmp[value]));
}