import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { FormAccordion } from "src/components/form/FormAccordion";
import { DeleteForeverOutlined, Edit, ExpandMoreOutlined } from "@mui/icons-material";
import React, { useMemo } from "react";
import { valueGetter } from "../../details-drawer/DetailsDrawer";
import { Item } from "src/models/item";

export const PackageListItem = ({ item, index, onEditTapped, onRemoveTapped, title, disableActions }) => {

    const isValid = useMemo(() => {
        if (!item) return false;

        return Item.isValid(item);
    }, [item]);

    return (
        <FormAccordion
            defaultExpanded={false}
            title={title}
            fontWeight={800}
            fontSize={18}
            sx={{
                border: isValid ? undefined : '1px solid red',
                mt: '0.5rem'
            }}
            header={
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Box sx={{ display: { md: "flex" }, gap: "0.5rem", textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '24rem' }}>
                        <Typography fontWeight={600} noWrap>{title}</Typography>
                    </Box>
                    {disableActions ? <ExpandMoreOutlined /> : (<Box>
                        <Button variant="outlined" onClick={(event) => {
                            event.stopPropagation();
                            onEditTapped(index);
                        }}>
                            <Edit />
                        </Button>
                        <Button color="error"
                            onClick={(event) => {
                                event.stopPropagation();
                                onRemoveTapped(index);
                            }}
                        ><DeleteForeverOutlined /></Button>
                    </Box>)}
                </Box>
            }
        >
            <Stack direction={"column"} gap={1}>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Box sx={{ display: { md: "flex" }, gap: "0.5rem" }}>
                        <Typography fontWeight={600}>Description:</Typography>
                        <Typography>{valueGetter(item?.description)}</Typography>
                    </Box>
                    <Box sx={{ display: { md: "flex" }, gap: "0.5rem" }}>
                        <Typography fontWeight={600}>Value:</Typography>
                        <Typography>{valueGetter(item?.actualValue)}</Typography>
                    </Box>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Box sx={{ display: { md: "flex" }, gap: "0.5rem" }}>
                        <Typography fontWeight={600}>Class:</Typography>
                        <Typography>{valueGetter(item?.class)}</Typography>
                    </Box>
                    <Box sx={{ display: { md: "flex" }, gap: "0.5rem" }}>
                        <Typography fontWeight={600}>NMFC Code:</Typography>
                        <Typography>{valueGetter(item?.nmfcCode)}</Typography>
                    </Box>
                    <Box sx={{ display: { md: "flex" }, gap: "0.5rem" }}>
                        <Typography fontWeight={600}>Manufacturer Part Number:</Typography>
                        <Typography>{valueGetter(item?.manufacturerPartNumber)}</Typography>
                    </Box>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Box sx={{ display: { md: "flex" }, gap: "0.5rem" }}>
                        <Typography fontWeight={600}>Weight:</Typography>
                        <Typography>{`${valueGetter(item?.actualWeight)}/${valueGetter(item?.actualWeightUOM)}`}</Typography>
                    </Box>
                    <Box sx={{ display: { md: "flex" }, gap: "0.5rem" }}>
                        <Typography fontWeight={600}>Quantity:</Typography>
                        <Typography>{`${valueGetter(item?.actualQty)}/${valueGetter(item?.actualQtyUOM)}`}</Typography>
                    </Box>
                    <Box sx={{ display: { md: "flex" }, gap: "0.5rem" }}>
                        <Typography fontWeight={600}>Dimension:</Typography>
                        <Typography>{`${valueGetter(item?.actualWidth)}x${valueGetter(item?.actualLength)}x${valueGetter(item?.actualHeight)} ${valueGetter(item?.actualDimensionsUOM)}`}</Typography>
                    </Box>
                </Box>
            </Stack>
        </FormAccordion>
    );
};
