import { Box, Dialog, DialogContent } from "@mui/material";
import { PackageForm } from "./form/PackageFields/PackageForm";

export const PackageDialog = ({ isOpen, onClose, onUpdated, packageIndex, isEdditing }) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth
            maxWidth="md"
            sx={{
                backdropFilter: "blur(5px) sepia(5%)",
            }}
            PaperProps={{ sx: { borderRadius: '16px' } }}
        >
            <DialogContent
                style={{
                    overflow: "hidden",
                    padding: 0,
                }}
            >
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    height={"100%"}
                    alignItems={"center"}
                >
                    <Box
                        display={"flex"}
                        flex={"column"}
                        bgcolor={"#f5f5f5"}
                        width={"100%"}
                        maxHeight={"95vh"}
                        overflow={"auto"}
                        padding={"30px"}
                    >
                        <PackageForm packageIndex={packageIndex} onUpdated={onUpdated} isEdditing={isEdditing} />
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}