import { forwardRef, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Drawer,
  IconButton,
  Modal,
  Tab,
  Typography,
  Dialog,
  Slide,
  Toolbar,
  AppBar,
  DialogContent,
  Grid,
} from "@mui/material";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import {
  DescriptionOutlined,
  RequestQuoteOutlined,
  LocalShippingOutlined,
  CloseOutlined,
  SummarizeOutlined,
} from "@mui/icons-material";

import { ItemDetails } from "src/pages/dashboard/components/ItemDetails";

import { ShipmentBasicInfo } from "./BasicInfo";
import { ShipmentActions } from "../Actions";
import {
  ShipmentDetailsProvider,
  useShipmentDetails,
} from "../../../context/ShipmentDetailsContext";
import { DeleteShipmentButton } from "../../DeleteShipmentButton";
import { TrackingDetails } from "./TrackingDetails";
import { useAuth } from "src/hooks/useAuth";
import { LocationsDetails } from "./LocationsDetails";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EditIconButton } from "./EditIconButton";
import { ShipmentHistory } from "../ShipmentHistory";
import { QuotationsDetails } from "./Quotation";
import DialogConfirmRequestQuotation from "../DialogConfirmRequestQuotation";

const tabs = [
  {
    title: "Details",
    icon: <DescriptionOutlined />,
  },
  {
    title: "Quotations",
    icon: <RequestQuoteOutlined />,
  },
  {
    title: "Tracking",
    icon: <LocalShippingOutlined />,
  },
  {
    title: "History",
    icon: <SummarizeOutlined />,
  }
];

const Transition = forwardRef(function Transition(
  props,
  ref,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export function ShipmentDetailsDrawer({
  selectedItem: shipment,
  onVisibilityChanged,
  onEditTapped,
  isClosedShipment
}) {
  const { session } = useAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [params] = useSearchParams();
  const navigate = useNavigate();

  const [selectedTabIndex, setSelectedTabIndex] = useState("0");
  const onTabSelected = (event, tabIndex) => {
    setSelectedTabIndex(tabIndex);
  };

  const toggleDrawer = (isVisible) => (event) => {
    setIsDrawerOpen(isVisible);
    onVisibilityChanged(isVisible);
    if (isClosedShipment) {
      navigate(`/closed-shipments?${params.toString()}`);
      return;
    }
    navigate(`/shipments?${params.toString()}`);
  };

  useEffect(() => {
    setIsDrawerOpen(!!shipment);
  }, [shipment]);

  const IsBackOffice = session.permissions.IsBackOffice;

  return (
    <ShipmentDetailsProvider shipment={shipment}>
      <Dialog
        fullScreen
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        TransitionProps={Transition}
        sx={{
          mt: '16px',
          mx: '120px',
        }}
        PaperProps={{ sx: { borderRadius: '16px 16px 0px 0px' } }}
      >
        <AppBar sx={{ position: 'relative', py: '0.7rem' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={toggleDrawer(false)}
              aria-label="close"
            >
              <CloseOutlined />
            </IconButton>
            <Box
              sx={{ pl: '0.5rem', width: '100%' }}
            >
              <Typography fontWeight={600} fontSize={'1.2rem'}>
                {shipment.primaryReference}
              </Typography>
              <Typography sx={{ opacity: 0.8 }}>
                {`Created by ${shipment.created.by.firstname} ${shipment.created.by.lastname}`}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '2rem' }}>
              <EditIconButton
                onEditTapped={() => onEditTapped(shipment)}
                isBackOffice={IsBackOffice}
                shipment={shipment}
              />
              <DeleteShipmentButton allowDeletion={IsBackOffice} />
            </Box>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box sx={{ mb: '0.8rem', display: "flex" }}>
            <RequestQuotationButton shipment={shipment} />
            <ShipmentActions />
          </Box>
          <TabContext value={selectedTabIndex} >
            <Box sx={{ display: 'flex' }}>
              <TabPanel value="0" sx={{ "&.MuiTabPanel-root": { padding: '0rem' }, width: '100%' }}>
                <ShipmentBasicInfo
                  shipment={shipment}
                  setSelectedTabIndex={setSelectedTabIndex}
                />
                <LocationsDetails shipment={shipment} />
                <ItemDetailsCard shipment={shipment} />
              </TabPanel>
              <TabPanel
                value="1"
                sx={{ "&.MuiTabPanel-root": { padding: '0rem' } }}
              >
                <QuotationsDetails />
              </TabPanel>
              <TabPanel
                value="2"
                sx={{
                  "&.MuiTabPanel-root": { padding: 0 },
                  width: '100%'
                }}
              >
                <TrackingDetails />
              </TabPanel>
              <TabPanel
                value={"3"}
                sx={{
                  "&.MuiTabPanel-root": { padding: 0 },
                  width: '100%'
                }}
              >
                <ShipmentHistory />
              </TabPanel>
              <ShipmentDrawerTabs onChange={onTabSelected} />
            </Box>
          </TabContext>

        </DialogContent>
      </Dialog>
    </ShipmentDetailsProvider>
  );
}

function ShipmentDrawerTabs({ onChange }) {
  const { tracking, quotations } = useShipmentDetails();
  return (
    <TabList
      onChange={onChange}
      aria-label="Shipment details"
      variant="scrollable"
      scrollButtons={true}
      allowScrollButtonsMobile
      orientation="vertical"
      TabIndicatorProps={{
        sx: { left: 0 }
      }}
      sx={{
        flexGrow: 1,
        flexShrink: 0
      }}
    >
      {tabs.map((item, index) => {
        if (item.title == "Quotations" && !quotations.length) return;
        if (item.title == "Tracking" && !tracking.length) return;
        return (
          <Tab
            icon={item.icon}
            iconPosition="start"
            label={item.title}
            value={index.toString()}
            key={item.title}
          />
        );
      })}
    </TabList>
  );
}

export function valueGetter(value, caseEmptyValue = "--") {
  return value ? value : caseEmptyValue;
}

function ItemDetailsCard({ shipment }) {
  return (<Box sx={{
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    gap: "0.75rem",
    mt: "0.75rem"
  }}>
    <Card variant="outlined" sx={{
      py: "0.5rem",
      px: "1rem",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "0.75rem"
    }}>
      <CardHeader component={() => <Box sx={{
        py: "0.25rem",
        pr: "0.5rem"
      }}>
        <Typography variant="h1" sx={{
          "&.MuiTypography-h1": {
            fontSize: "1.25rem"
          }
        }}>
          Packages
        </Typography>
        <Typography color={"GrayText"}>
          {valueGetter((shipment.locations[0].items?.length), "No ")}{" "}
          Package(s)
        </Typography>
      </Box>} sx={{
        "&.MuiCardHeader-root": {
          p: 0
        }
      }} />
      {!!shipment.locations[0].items?.length && <Divider />}
      <CardContent sx={{
        "&.MuiCardContent-root": {
          p: 0
        }
      }}>
        <Grid container spacing={2}>
          {shipment.locations[0].items?.map(item => <Grid item md={4}><ItemDetails item={item} key={item._id} expanded /></Grid>)}
        </Grid>
      </CardContent>
    </Card>
  </Box>);
}

function RequestQuotationButton({ shipment }) {
  const {
    isQuotationFeed,
    isQuotationLoading,
    isBackofficeUser,
    isLTLManager,
    getCarrierQuotations
  } = useShipmentDetails();

  const [openDialogConfirmQuotation, setOpenDialogConfirmQuotation] = useState(false);

  const needsValidation =
    !!(shipment?.poNumber?.length > 0) &&
    !shipment.validated &&
    ((shipment.mode == "TL" &&
      !shipment.integrations?.find(
        (integration) => integration?.target == "trinity"
      )?.status == "success") ||
      (shipment.mode == "LTL" &&
        false && // TODO Remove when migrate validation to booking method.
        shipment.integrations?.find(
          (integration) => integration?.target == "banyan"
        )?.status == "success"));


  return <Box>
    {
      (isBackofficeUser || isLTLManager) &&
        !needsValidation &&
        shipment.mode == "LTL" &&
        !isQuotationFeed ? <> <LoadingButton
          loading={isQuotationLoading}
          loadingPosition="start"
          startIcon={<RequestQuoteOutlined />}
          onClick={() => {
            if (shipment.integrated) {
              setOpenDialogConfirmQuotation(true);
            } else {
              getCarrierQuotations();
            }
          }}
          disabled={
            isQuotationFeed ||
            shipment.intregations?.some(
              (integration) =>
                integration.target == "banyan" && integration == "success"
            )
          }
          variant="outlined"
          color={isQuotationFeed ? "success" : "primary"}
          sx={{
            marginRight: "8px",
            "&.Mui-disabled": {
              color: (theme) => theme.palette.success.darker,
              border: (theme) => theme.palette.success.darker,
              opacity: 0.8,
            },
          }}
        >
          {isQuotationLoading ? "Loading Quotation" : "Request Quotation"}
        </LoadingButton> 
        <DialogConfirmRequestQuotation 
          shipmentStatus={shipment.status}
          onConfirm={() => {
            setOpenDialogConfirmQuotation(false);
            getCarrierQuotations();
          }} 
          onClose={() => { setOpenDialogConfirmQuotation(false) }} 
          open={openDialogConfirmQuotation} /> 
      </> : <></>
    }
  </Box>;
}