import { Box, Divider, Grid, Stack, Typography } from "@mui/material";

import { LocationDetails } from "src/components/location-details";

import { fDateString } from "src/utils/formatTime";

import { Location } from "src/models/location";
import { shipmentServices } from "../../../constants/services";
import { valueGetter } from "./DetailsDrawer";
import { ServiceField, ServicesDetails } from "./ServicesDetails";

export function LocationsDetails({ shipment }) {
  return (
    <>
      {/* PICKUP & STOP */}
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "space-between",
          width: "100%",
          gap: "0.75rem",
          mt: "0.75rem"
        }}
      >
        {shipment.locations.map(location => {
          const services = shipmentServices.find(servicePerMode => servicePerMode.mode == shipment.mode);
          const isPickup = location.type === 'pickup';

          return (<LocationDetails
            locationLabel={isPickup ? 'Origin' : 'Destination'}
            location={new Location().fromShipment(location)}
            type={location.type}
            customFields={
              <Stack>
                <TargetDates early={location.targetEarly} late={location.targetLate} />
                <Divider sx={{ mt: '0.8rem' }} />
                <ContactInfo
                  name={location.contacts[0].name}
                  phone={location.contacts[0].phone}
                  email={location.contacts[0].email}
                />
                <Services
                  services={services[isPickup ? 'pickups' : 'stops']}
                  shipment={shipment}
                />
              </Stack>
            }
          />)
        })}
      </Box>
      <ServicesDetails shipment={shipment} />
    </>
  );
}

function ContactInfo({ name, phone, email }) {
  return (
    <Box sx={{ mt: '0.5rem' }}>
      <Typography fontWeight={600} mt={1}>
        POC: {valueGetter(name)}
      </Typography>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}>
        <Box sx={{
          display: {
            md: "flex",
            xs: "block"
          },
          width: '100%',
          py: "0.25rem",
          gap: "0.5rem"
        }}>
          <Typography>Phone:</Typography>
          <Typography fontWeight={600} textAlign={{
            md: "right",
            xs: "left"
          }}>
            {valueGetter(phone)}
          </Typography>
        </Box>
        <Box sx={{
          display: {
            md: "flex",
            xs: "block"
          },
          width: '100%',
          py: "0.25rem",
          gap: "0.5rem"
        }}>
          <Typography>E-Mail:</Typography>
          <Typography fontWeight={600} textAlign={{
            md: "right",
            xs: "left"
          }}>
            {valueGetter(email)}
          </Typography>
        </Box>
      </Box></Box>);
}

function Services({ services, shipment }) {
  return (
    <Box py={1}>
      <Typography fontWeight={600}>Services</Typography>
      <Grid container gap={'1rem'}>
        {services.map((service, index) => {
          const [category, key] = service.name.split(".");
          return (
            <Grid item>
              <ServiceField service={service} key={service.name + index} checked={shipment?.[category] ? shipment?.[category][key] : false} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

function TargetDates({ early, late }) {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }}>
      <Box sx={{
        display: {
          md: "flex",
          xs: "block"
        },
        width: '100%',
        gap: "0.5rem"
      }}>
        <Typography>Target Early: </Typography>
        <Typography fontWeight={600} textAlign={{
          md: "right",
          xs: "left"
        }}>
          {valueGetter(fDateString(early))}
        </Typography>
      </Box>
      <Box sx={{
        display: {
          md: "flex",
          xs: "block"
        },
        width: '100%',
        gap: "0.5rem"
      }}>
        <Typography>Target Late: </Typography>
        <Typography fontWeight={600} textAlign={{
          md: "right",
          xs: "left"
        }}>
          {valueGetter(fDateString(late))}
        </Typography>
      </Box>
    </Box>);
}
