import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { valueGetter } from "src/pages/shipments/components/details-drawer/DetailsDrawer";
import { fCurrency } from "src/utils/formatNumber";
import { HazardousDetails } from "./HazardousDetails";
import { palletCountUnits, palletCoutLabel } from "src/pages/shipments/components/units";

export function ItemDetails({ item, ...props }) {
  const qtyLabel = palletCoutLabel[palletCountUnits.findIndex(v => v === item.actualQtyUOM)];

  return (
    <Stack spacing={2} direction={"column"}>
      <Accordion variant="outlined" defaultExpanded={props.expanded ? props.expanded : false}>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          sx={{ display: "flex", gap: 2 }}
        >
          <Box
            component="span"
            sx={{
              maxWidth: "20rem",
              display: "flex",
              justifyContent: "space-between",
              py: "0.25rem",
            }}
          >
            <Box display={"flex"} gap={1}>
              <Typography fontWeight={600}>{valueGetter(item.itemId)}</Typography>
              <Typography color={"GrayText"}>- Package ID</Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Card
            variant="elevation"
            elevation={0}
            sx={{
              py: "0.5rem",
              px: "1rem",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "0.75rem",
              "&.MuiCard-root": {
                p: 0,
              },
            }}
          >
            <CardContent
              sx={{
                "&.MuiCardContent-root": {
                  p: 0,
                },
              }}
            >
              {/* PO Number */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: "0.25rem",
                }}
              >
                <Typography>Description</Typography>
                <Typography fontWeight={600}>
                  {valueGetter(item.description)}
                </Typography>
              </Box>

              {/* Shipment Number */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: "0.25rem",
                }}
              >
                <Typography>Value</Typography>
                <Typography fontWeight={600}>
                  {fCurrency(item.actualValue)}
                </Typography>
              </Box>

              {/* Customer */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: "0.25rem",
                }}
              >
                <Typography>Class</Typography>
                <Typography fontWeight={600}>
                  {valueGetter(item.class)}
                </Typography>
              </Box>
              {/* COX PO NUMBER */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: "0.25rem",
                }}
              >
                <Typography>NMFC Code</Typography>
                <Typography fontWeight={600}>
                  {valueGetter(item.nmfcCode)}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: "0.25rem",
                }}
              >
                <Typography>Manufacturer Part Number</Typography>
                <Typography fontWeight={600}>
                  {valueGetter(item.manufacturerPartNumber)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: "0.25rem",
                }}
              >
                <Typography>Weight</Typography>
                <Typography fontWeight={600}>
                  {`${item.actualWeight} ${valueGetter(item.actualWeightUOM)
                    } - ${(
                      valueGetter(item.actualWeightCountType)
                    )
                      .split("_")
                      .join(" ")}`}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: "0.25rem",
                }}
              >
                <Typography>{`${qtyLabel} count`}</Typography>
                <Typography fontWeight={600}>
                  {`${item.actualQty} - ${item.actualQtyUOM}`}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  py: "0.25rem",
                }}
              >
                <Typography>Dimensions</Typography>
                <Typography fontWeight={600}>
                  {`${item.actualLength} x ${item.actualWidth} x ${item.actualHeight} ${item.actualDimensionsUOM} `}
                </Typography>
              </Box>
              {item.actualPieces && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    py: "0.25rem",
                  }}
                >
                  <Typography>{`Piece count`}</Typography>
                  <Typography fontWeight={600}>
                    {`${item.actualPieces} - ${item.actualPiecesUOM}`}
                  </Typography>
                </Box>
              )}
              {
                item.hazardous && (
                  <Box display={"flex"} flexDirection={"column"} marginTop={"4px"}>
                    <Divider />
                    <Typography variant="h1"
                      sx={{
                        "&.MuiTypography-h1": {
                          fontSize: "1.1rem"
                        },
                        mt: '16px',
                        mb: '4px'
                      }}>
                      Hazmat Details
                    </Typography>
                    <HazardousDetails hazardous={item.hazardous} />
                  </Box>
                )
              }
            </CardContent>
          </Card>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
