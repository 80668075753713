import { RequestQuoteOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { useState } from "react";
import { useAuth } from "src/hooks/useAuth";
import { fCurrency } from "src/utils/formatNumber";
import { useShipmentDetails } from "../../context/ShipmentDetailsContext";
import DialogConfirmRequestQuotation from "./DialogConfirmRequestQuotation";

export function QuotationsDetails() {
  const { quotations } = useShipmentDetails();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "0.75rem" }}>
      {quotations.map((quotation) => (
        <CarrierQuotation key={quotation.quoteId} quotation={quotation} />
      ))}
    </Box>
  );
}

function CarrierQuotation({ quotation }) {
  const {
    shipment,
    confirmShipment,
    isConfirmationLoading,
    selectedQuotation,
    setSelectedQuotation,
    getCarrierQuotations
  } = useShipmentDetails();

  const { session } = useAuth();

  const isBackOffice = session.permissions.IsBackOffice;
  const [isThisQuotationConfirmationLoading, setThisQuotationLoading] =
    useState(false);

  const [ openDialogConfirmQuotation, setOpenDialogConfirmQuotation ] = useState(false);

  function handleShipmentConfirmation(quotation) {
    setOpenDialogConfirmQuotation(false);
    confirmShipment(shipment._id, quotation.loadId, quotation.quoteId, quotation.carrierName);
    setSelectedQuotation(quotation);
    setThisQuotationLoading(true);
  }

  const isSelectedQuotation = (quotation) =>
    selectedQuotation?.quoteId == quotation.quoteId;

  function isCarrierBlocked(carrierName) {
    const blockedCarriers = [];

    return blockedCarriers.includes(carrierName);
  }

  return (
    <Card
      variant="outlined"
      sx={{
        py: "0.5rem",
        px: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
      }}
    >
      <CardHeader
        sx={{
          "&.MuiCardHeader-root": { p: 0 },
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "center",
          alignItems: "baseline",
          gap: "1rem",
        }}
        title={quotation.carrierName}
        subheader={"Carrier"}
      />
      <CardContent
        sx={{
          "&.MuiCardContent-root": { p: 0 },
          display: "flex",
          flexDirection: "column",
          gap: 2,
          // justifyContent: "space-between",
          // alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>Net Price</Typography>
          <Typography fontWeight={600} fontSize={"1.2rem"}>
            {fCurrency(quotation.netPrice)}
          </Typography>
        </Box>
        {isBackOffice && quotation.carrierNote && (
          <Box>
            <Typography fontWeight={600}>Carrier Note</Typography>
            {/* <Typography>{valueGetter(quotation.carrierNote)}</Typography> */}
            <div
              dangerouslySetInnerHTML={{ __html: quotation.carrierNote }}
            ></div>
          </Box>
        )}
        {
          <>
            <LoadingButton
              loading={
                isConfirmationLoading && isThisQuotationConfirmationLoading
              }
              loadingPosition="start"
              startIcon={<RequestQuoteOutlined />}
              onClick={() => setOpenDialogConfirmQuotation(true)}
              disabled={
                (!!selectedQuotation && shipment.integrated) ||
                isCarrierBlocked(quotation?.carrierName)
              }
              className={isSelectedQuotation(quotation) ? "selected" : ""}
              variant="text"
              color={
                !!selectedQuotation &&
                  shipment.integrated &&
                  isSelectedQuotation(quotation)
                  ? "success"
                  : "primary"
              }
              sx={{
                "&.Mui-disabled.selected": {
                  color: (theme) => theme.palette.success.darker,
                  border: (theme) => theme.palette.success.darker,
                  opacity: 0.8,
                },
                fontSize: "1rem",
                display: "flex",
                width: "100%",
              }}
            >
              {isCarrierBlocked(quotation?.carrierName)
                ? "Blocked Carrier"
                : "Confirm Shipment Quotation"}
            </LoadingButton>
            <DialogConfirmRequestQuotation
              shipmentStatus={shipment.status}
              onConfirm={() => {
                if (shipment.integrated) {
                  handleShipmentConfirmation(quotation);
                } else {
                  getCarrierQuotations();
                }
              }} 
              onClose={() => {setOpenDialogConfirmQuotation(false)}} 
              open={openDialogConfirmQuotation} 
            />
          </>
        }
      </CardContent>
    </Card>
  );
}