import { ChevronRight } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";

export function LocationDetails({ location, ...props }) {
  const type = props.type ? props.type : null;
  const locationLabel = props.locationLabel
    ? props.locationLabel
    : type
      ? `${type} location`
      : "Location";

  const hasCustomFields = !!props.customFields;

  return (
    <Card
      variant="outlined"
      sx={{
        py: "0.5rem",
        px: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
      }}
    >
      <CardHeader
        title={location.name}
        subheader={locationLabel}
        sx={{
          "&.MuiCardHeader-root": {
            p: 0,
          },
        }}
      />
      <Divider />
      <CardContent
        sx={{
          "&.MuiCardContent-root": {
            p: 0,
          },
        }}
      >
        <FieldLine field={"Address 1"} value={location.addr1} />
        <FieldLine field={"Address 2"} value={location.addr2} />
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <FieldLine field={"City"} value={location.city} />
          <FieldLine field={"State"} value={location.state} />
          <FieldLine field={"Country"} value={location.ctry} />
          <FieldLine field={"ZIP"} value={location.zip} />
        </Box>
        {location.timezone && <FieldLine field={"Timezone"} value={location.timezone} />}

        <Box
          sx={{
            display: {
              md: "flex",
              xs: "block",
            },
            justifyContent: "space-between",
            py: "0.25rem",
            gap: "0.5rem",
            width: "100%",
          }}
        >
          <Typography sx={{ whiteSpace: "nowrap" }}>
            {location.refType}
          </Typography>
          {location.refId?.name &&
            (location.refType == "Warehouse" ? (
              <Box display={"flex"} alignItems={"center"}>
                <Typography sx={{ whiteSpace: "nowrap" }}>
                  {location.refId?.name}
                </Typography>

                <Button
                  variant="text"
                  href={`/warehouses/${location.refId._id}`}
                  sx={{
                    p: 0,
                    minWidth: "fit-content",
                  }}
                >
                  <ChevronRight />
                </Button>
              </Box>
            ) : (
              <Typography fontWeight={600}>{location.refId?.name}</Typography>
            ))}
        </Box>
        <Box sx={{ width: "100%" }}>
          {hasCustomFields && props.customFields}
        </Box>
      </CardContent>
    </Card>
  );
}

export function FieldLine({ field, value }) {
  return (
    <Box
      sx={{
        display: {
          md: "flex",
          xs: "block",
        },
        py: "0.25rem",
        gap: "0.5rem",
        width: "100%",
      }}
    >
      <Typography sx={{ whiteSpace: "nowrap" }}>{`${field}:`}</Typography>
      <Typography
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        fontWeight={600}
      >
        {value ? value : "--"}
      </Typography>
    </Box>
  );
}
