export class Location {
  name = null;
  addr1 = null;
  addr2 = null;
  city = null;
  state = null;
  zip = null;
  ctry = "USA";
  timezone = null;

  static toJson(location) {
    if (!location.refType || !location.refId) {
      location.refType = null;
      location.refId = null;
    }

    return location;
  }

  fromWarehouse(warehouse) {
    this.name = warehouse.name;
    this.addr1 = warehouse.location;
    this.zip = warehouse.zip;
    this.city = warehouse.city;
    this.timezone = warehouse.timezone;
    this.state = warehouse.state;
    // this.addr1 = warehouse.address;

    return this;
  }

  fromStop(stop) {
    this.name = `Receiver: ${stop.destName}`;
    this.addr1 = stop.destAddr1;
    this.addr2 = stop.destAddr2;
    this.city = stop.destCity;
    this.state = stop.destState;
    this.zip = stop.destZip;
    if (!!stop.destCtry && stop.destCtry != this.ctry) {
      this.ctry = stop.destCtry;
    }
    return this;
  }

  fromShipment(location) {
    this.name = `${location.type === 'pickup' ? 'Shipper' : 'Receiver'}: ${location.name}`;
    this.addr1 = location.address1;
    this.addr2 = location.address2;
    this.city = location.city;
    this.state = location.state;
    this.zip = location.zip;
    if (!!location.country && location.country != this.ctry) {
      this.ctry = location.country;
    }
    return this;
  }

  static toShipment(location, type) {
    location.type = type;
    location.address1 = location.addr1;
    location.address2 = location.addr2;
    location.country = location.ctry;
    return location;
  }
}