import { TabContext } from "@mui/lab";
import {
    Box,
    Button,
    Card,
    Container,
    Grid,
    Typography
} from "@mui/material";
import { isEqual } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";
import { CombineShipmentsModal } from "./components/CombineShipmentsModal";
import { ShipmentsDataGrid } from "./components/ShipmentsDataGrid";
import { VisibilitySwitch } from "./components/VisibilitySwitch";
import { WeekCalendar } from "./components/week-calendar/WeekCalendar";
import { backofficeShipmentColumns } from "./constants/backofficeShipmentsColumns";
import { useShipmentQueries } from "./hooks/useShipmentQueries";

const initialPageState = {
    selectedCalendarDate: new Date().setHours(0, 0, 0, 0),
    isCalendarVisible: false,
    selectedTab: 'opened',
}

const tabs = [
    {
        index: "opened",
        title: "Opened",
        iconComponent: null,
    },
];

export default function BackofficeShipmentsPage() {
    const { session } = useAuth();
    const {
        getBackofficeShipments,
        backofficeShipments,
        isLoadingBackofficeShipments, isRefetchingBackofficeShipments,
    } = useShipmentQueries();

    const { shipmentId } = useParams();

    const [pageState, setPageState] = useState(initialPageState);
    const onSelectedDateChange = (selectedDay) => {
        setPageState({ ...pageState, selectedCalendarDate: selectedDay });
    };

    const toggleCalendarVisibility = () => {
        setPageState({ ...pageState, isCalendarVisible: !pageState.isCalendarVisible });
    }

    const onTabSelected = (tab) => {
        console.log(tab);
        setPageState({ ...pageState, selectedTab: tab });
    };

    const intervalRef = useRef(null);

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        getBackofficeShipments(pageState.selectedTab)

        const selectedTab = tabs.find(tab => tab.index === pageState.selectedTab);

        if (selectedTab && selectedTab.index === "closed") {
            intervalRef.current = setInterval(() => getBackofficeShipments(pageState.selectedTab), 300000);
        } else {
            intervalRef.current = setInterval(() => getBackofficeShipments(pageState.selectedTab), 10000);
        }

        return () => clearInterval(intervalRef.current);
    }, [pageState.selectedTab]);


    // Filtering the data on user actions
    const filteredData = useMemo(() => {
        let shipments = backofficeShipments;
        if (!Array.isArray(shipments)) return [];

        if (pageState.isCalendarVisible && pageState.selectedCalendarDate) {
            return shipments.filter((shipment) => {
                const dates = shipment.locations.map(location => new Date(location.targetLate).setHours(0, 0, 0, 0));
                const currentDate = pageState.selectedCalendarDate;

                return dates.some(date => isEqual(date, currentDate));
            });
        }
        return shipments;

    }, [backofficeShipments, pageState.isCalendarVisible, pageState.selectedCalendarDate])

    const columns = backofficeShipmentColumns(session);

    function DataGridHeaderActionsButtons() {
        const isSelectionValidToCombine = useMemo(() => {
            const isMultipleSeleted = selectedItems.length > 1;
            return isMultipleSeleted;
        }, [selectedItems])

        return (
            <Box gap={1}
                sx={{
                    button: { fontSize: '0.8rem' },
                    display: 'flex',
                }}
            >
                {tabs.map((item) => (
                    <Button
                        variant={item.index == pageState.selectedTab ? "outlined" : "text"}
                        key={item.index}
                        sx={{ height: '2rem' }}
                        onClick={() => onTabSelected(item.index)}
                    >{item.title}</Button>
                ))}

                {/* COMBINE SELECTED SHIPMENTS */}
                {selectedItemsIds.length > 1 &&
                    <Button
                        variant={pageState.selectedTab == "combined" ? "outlined" : "text"}
                        onClick={toggleCombineModalVisibility}
                        sx={{ maxHeight: 'fit-content' }}
                        disabled={!isSelectionValidToCombine}
                    > Combine Selection</Button>
                }
            </Box>
        )
    }

    const [selectedItemsIds, setSelectedItemsIds] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    useEffect(() => {
        if (selectedItemsIds) {
            setSelectedItems(filteredData.filter(shipment => selectedItemsIds.includes(shipment._id)))
        }
    }, [selectedItemsIds])

    const [isCombineModalOpened, setIsCombineModalOpened] = useState(false);

    const toggleCombineModalVisibility = () => setIsCombineModalOpened(!isCombineModalOpened);

    return (
        <>
            <Helmet>
                <title> All Shipments | {session.tenant.title} </title>
            </Helmet>
            <Container maxWidth="false">
                <Typography
                    variant="h4"
                    sx={{ mb: 1, mt: 3, textAlign: "center", display: "flex" }}
                >
                    All Shipments
                </Typography>
                <Box sx={{ my: 4, display: { md: "none" } }} />
                <Grid container item xs={12}>
                    <Card sx={{ p: 2 }}>
                        <VisibilitySwitch
                            label="Filter by Day"
                            isVisible={!pageState.isCalendarVisible}
                            setVisibility={toggleCalendarVisibility}
                        >
                            <WeekCalendar
                                startingDate={pageState.selectedCalendarDate}
                                onClickHandler={onSelectedDateChange}
                            />
                        </VisibilitySwitch>
                        <TabContext value={pageState.selectedTab}>
                            <section className="dataTable">
                                <ShipmentsDataGrid
                                    shipments={filteredData}
                                    isLoadingData={isLoadingBackofficeShipments || isRefetchingBackofficeShipments}

                                    columns={columns}

                                    selectedItemId={shipmentId}

                                    customToolbarActions={DataGridHeaderActionsButtons}

                                    disableMultipleRowSelection={!['combined', 'closed'].includes(pageState.selectedTab)}
                                    treeData={pageState.selectedTab == 'combined' && !isLoadingBackofficeShipments}
                                    getTreeDataPath={(row) => row.hierarchy || []}
                                    defaultGroupingExpansionDepth={1}

                                    onRowSelectionModelChange={(selectedItemsIds, details) => {
                                        setSelectedItemsIds(selectedItemsIds)
                                        console.log(selectedItemsIds)
                                    }}
                                />
                            </section>
                        </TabContext>
                    </Card>
                </Grid>
                <CombineShipmentsModal
                    shipments={selectedItems}
                    open={isCombineModalOpened} handleClose={toggleCombineModalVisibility}
                    handleRemove={(shipmentId) => setSelectedItems(prev => prev.filter(shipment => shipment._id != shipmentId))}
                />
            </Container>
        </>
    );
}