import { Box, Typography } from "@mui/material";
import React from "react";
import PropTypes from 'prop-types';
import { FormInput } from "src/components/form/FormInput";
import { ChangeNoteFormProvider } from "../context/ChangeNoteFormContext";
import { useChangeNoteQueries } from "../hooks/useChangeNoteQueries";
import { LoadingButton } from "@mui/lab";
import { StandardDialog } from "src/components/dialog/Dialog";

export function DialogFormReleaseNote(props) {
    const {
        newNote,
        isCreationLoading,
        updateNote,
        isUpdatingLoading
    } = useChangeNoteQueries();

    const handleClose = () => {
        props.onClose();
    }

    return <StandardDialog onClose={handleClose} isOpen={props.open}>
        <Box sx={{
            display: "flex",
            flexDirection: 'column',
            width: '100%',
        }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    mb: '2rem'
                }}
            >
                <Typography
                    fontWeight={600}
                    variant="h1"
                    sx={{ "&.MuiTypography-h1": { fontSize: "1.5rem" } }}
                >
                    {'Add release note'}
                </Typography>
            </Box>
            <ChangeNoteFormProvider
                onSave={!props.changeNote ? newNote : updateNote}
                reset={props.reset ? props.reset : false}
                onClose={props.onClose}
                useFormParams={{ defaultValues: props.changeNote }}>
                <FormInput
                    label="Title"
                    name={`changeNote.title`}
                    type="text"
                    required
                    fullWidth />
                <Box height="12px" />
                <FormInput
                    label="Subtitle"
                    name={`changeNote.subtitle`}
                    type="text"
                    required
                    fullWidth />
                <Box height="12px" />
                <FormInput
                    label="Content..."
                    name={`changeNote.content`}
                    type="text"
                    fullWidth
                    required
                    multiline
                    minRows={4} />
                <Box height="12px" />
                <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={!props.changeNote ? isCreationLoading : isUpdatingLoading}
                    fullWidth>
                    {!props.changeNote ? 'Create' : 'Update'}
                </LoadingButton>
            </ChangeNoteFormProvider>
        </Box>
    </StandardDialog>
}

DialogFormReleaseNote.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};