import { z } from "zod";

export class Service {
  static get createSchema() {
    return z.object({
      excessLength12ftAndOver: z.boolean(),
      excessLength12to15ft: z.boolean(),
      excessLength8to11ft: z.boolean(),
      airportPickup: z.boolean(),
      guaranteedDelivery: z.boolean(),
      hazardousCargoCharge: z.boolean(),
      insideDelivery: z.boolean(),
      liftGateDelivery: z.boolean(),
      liftGatePickup: z.boolean(),
      limitedAccess: z.boolean(),
      notifyBeforeDelivery: z.boolean(),
      protectFromFreezing: z.boolean(),
      residentialDelivery: z.boolean(),
      residentialPickup: z.boolean(),
      scheduleApptCharge: z.boolean(),
    });
  }
}