import {
  Box,
  Card,
  Container,
  Grid,
  LinearProgress,
  Typography
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { CarrierService } from "src/api/carrier.service";
import { DataTable } from "src/components/datagrid";
import CustomToolbar from "src/components/datagrid/widgets/CustomToolbar";
import { useAuth } from "src/hooks/useAuth";
import { useCarrierQueries } from "./hooks/useCarrierQueries";
import { useEffect } from "react";
import { CarrierColumns } from "./constants/CarriersColumns";
import { CarrierDetailsDrawers } from "./components/CarrierDetailsDrawer";

export function CarriersPage() {
  const { session } = useAuth();
  const { carrierId } = useParams();
  const { carriers, getCarriers, isCarriersLoading } = useCarrierQueries();

  useEffect(() => { getCarriers() }, []);

  return (
    <>
      <Helmet>
        <title> Carriers | {session.tenant.title} </title>
      </Helmet>
      <Container maxWidth="false" sx={{ width: 'fit-content', maxWidth: '100%' }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Typography
            variant="h4"
            sx={{ mb: 1, mt: 3, textAlign: "center", display: "flex" }}
          >
            Carriers
          </Typography>
        </Box>
        <Box sx={{ my: 4, display: { md: "none" } }} />
        <Grid container item xs={12} width={'100%'} justifyContent={'center'}>
          <Card sx={{ minWidth: '50vw' }}>
            <section className="dataTable">
              <DataTable
                selectedItemId={carrierId}
                loading={isCarriersLoading}
                loadData={carriers ?? []}
                columns={CarrierColumns()}
                customDrawerComponent={CarrierDetailsDrawers}
                className="datagrid"
                pagination
                autoHeight
                density="compact"
                disableDensitySelector
                initState={{
                  columns: {
                    columnVisibilityModel: { id: false },
                  },
                }}
                slots={{
                  toolbar:  () => <CustomToolbar columns={CarrierColumns()} rows={carriers} title="Carriers"/>,
                  loadingOverlay: LinearProgress,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    quickFilterProps: { debounceMs: 500 },
                    loadData: carriers ?? [],
                  },
                }}
                detailsDrawerTitle="Carriers"
                // detailColumns={CarrierDetailColumns}
                modelKey={"carriers"}
                modelGridSetup={{}}
              />
            </section>
          </Card>
        </Grid>
      </Container>
    </>
  );
}
