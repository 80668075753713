import { createTheme } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license-pro";
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "./components/scroll-to-top";
import { AuthProvider } from "./context/auth";
import ThemeProvider from "./theme";
import { SnackbarProvider } from "./context/snackbar/SnackbarProvider";
import AppRouter from "./router/AppRouter";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { QueryClient, QueryClientProvider } from "react-query";

LicenseInfo.setLicenseKey(
  '359f041c86974b07caea40468ac5b52aTz04ODEwNCxFPTE3NDQyOTA2MDMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

export const theme = createTheme({
  palette: {
    primary: {
      main: "#637381",
      darker: "#454F5B",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
    status: {
      pending: "#FFB74D",
      completed: "#AED581",
      rejected: "#F44336",
      contrastText: "#454F5B",
    },
  },
});

const client = new QueryClient()

function App() {

  return (
    <QueryClientProvider client={client}>
      <HelmetProvider>
        <SnackbarProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="App">
              <ThemeProvider theme={theme}>
                <ScrollToTop />
                <AuthProvider>
                  <AppRouter />
                </AuthProvider>
              </ThemeProvider>
            </div>
          </LocalizationProvider>
        </SnackbarProvider>
      </HelmetProvider>
    </QueryClientProvider>
  );
}

export default App;
