import React, { useMemo } from "react";
import { useMask } from "@react-input/mask";
import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export const FormInputMask = (props) => {
  const { name, selectCountry } = props;
  const { control } = useFormContext()

  const phoneMask = useMemo(() => {
    switch (selectCountry) {
      case "MX":
        return "(__) ____-____";
      case "CA":
        return "(___) ___-____";
      case "USA":
        return "(___) ___-____";
      default:
        return "(___) ___-____";
    }
  }, [selectCountry]);

  const inputRef = useMask({ mask: phoneMask, replacement: { _: /\d/ } });

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return <TextField
          {...props}
          {...field}
          name={name}
          label="Phone"
          type="text"
          inputRef={inputRef}
        />;
      }}
    />
  );
};
