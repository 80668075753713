import {
  useEffect,
  useState
} from "react";
import {
  useFieldArray,
  useForm
} from "react-hook-form";
import dayjs from "dayjs";
import {
  Shipment
} from "src/models/shipment";
import {
  useSnackbar
} from "src/hooks/useSnackbar";

export function useShipmentFormHook(props) {
  const useFormParams = props?.useFormParams;
  const defaultValues = useFormParams?.defaultValues;
  const {
    setSnackbarMessage
  } = useSnackbar();

  const form = useForm({
    ...useFormParams,
    defaultValues,
    mode: "onChange",
  });

  const {
    formState: {
      errors,
      isSubmitSuccessful
    },
    reset,
    handleSubmit,
    setError,
    clearErrors,
  } = form;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isSubmitSuccessful) {
      props.reset && reset();
    }
  }, [isSubmitSuccessful, reset, errors]);

  const onSubmitHandler = async (values) => {
    setIsLoading(true);

    try {
      // MAP ITEMS
      const shipment = Shipment.toJson({
        ...values
      });

      // Check locations sort
      if (shipment.locations[0].type !== 'pickup') {
        setSnackbarMessage({
          severity: 'error',
          message: 'The first location must be a pickup.'
        });
        return;
      }

      if (shipment.locations[shipment.locations.length - 1].type !== 'delivery') {
        setSnackbarMessage({
          severity: 'error',
          message: 'The last location must be a delivery.'
        });
        return;
      }

      const result = await props.onSave(shipment);
      if (result?.errors) {
        updateErrors(result.errors, setError);
      }
      setIsLoading(false);
    } catch (error) {
      setSnackbarMessage({
        severity: 'error',
        message: error.message
      });
      setIsLoading(false);
    }
  };

  const {
    fields: itemsFields,
    append: appendItem,
    remove: removeItemFromIndex,
    update: updateItem,
  } = useFieldArray({
    control: form.control,
    name: "items",
  });

  const {
    fields: poNumberFields,
    append: appendPoNumber,
    remove: removePoNumberFromIndex,
  } = useFieldArray({
    control: form.control,
    name: "poNumber",
  });

  const {
    fields: orderNumberFields,
    append: appendOrderNumber,
    remove: removeOrderNumberFromIndex,
  } = useFieldArray({
    control: form.control,
    name: "ordersNumbers",
  });
  const {
    fields: shipmentNumbers,
    append: appendShipmentNumber,
    remove: removeShipmentFromIndex,
  } = useFieldArray({
    control: form.control,
    name: "shipmentNumber",
  });

  const mapFields = (params, prefix = "") => {
    if (!params) return;
    Object.entries(params).forEach(([key, value]) => {
      if (!value || value == "transfer") return;
      switch (key) {
        case "items":
          value.forEach((item) => {
            appendValidatedItem(item);
          });
          return;
        case "shipmentNumber":
          if (value?.toString().includes(",")) {
            const numbers = value.split(",");
            numbers.forEach((shipmentNumber) => {
              appendShipmentNumber(shipmentNumber);
            });
          } else {
            appendShipmentNumber(value);
          }

          return;
        default:
          break;
      }

      if (typeof value === "object")
        return mapFields(value, `${prefix + key}.`);

      if (key.match("poNumber")) {
        const poNumbers = value.split(",");
        return mapFields(poNumbers, `${prefix + key}.`);
      }

      if (
        key.match("targetDeliveryEarly") ||
        key.match("targetDeliveryLate") ||
        key.match("targetShipEarly") ||
        key.match("targetShipLate")
      ) {
        return form.setValue(prefix + key, dayjs(value));
      }
      console.log(prefix, key, value);
      form.setValue(prefix + key, value ?? false);
    });
  };

  const appendValidatedItem = (item) => {
    const items = form.getValues("items");

    let existingItem = items?.some((itemField) => {
      if (!itemField) return false;

      const isDuplicatedItemId =
        itemField.itemId && item?.itemId && itemField.itemId == item?.itemId;
      const isDuplicatedDescription =
        itemField.description &&
        item?.description &&
        itemField.description == item?.description;

      return isDuplicatedItemId && isDuplicatedDescription;
    });

    if (existingItem) return;
    else appendItem(item);
  };

  return {
    isLoading,
    form,
    handleSubmit,
    onSubmitHandler,
    clearErrors,
    items: {
      fields: itemsFields,
      remove: removeItemFromIndex,
      append: appendItem,
      update: updateItem,
    },
    poNumbers: {
      fields: poNumberFields,
      remove: removePoNumberFromIndex,
      append: appendPoNumber,
    },
    ordersNumbers: {
      fields: orderNumberFields,
      append: appendOrderNumber,
      remove: removeOrderNumberFromIndex,
    },

    mapFields,
  };
}

function updateErrors(errors, setError) {
  errors.forEach((error) => {
    const param = error.param.replace("[", ".").replace("]", "");
    setError(param, {
      type: "manual",
      message: error.msg
    });
  });
}