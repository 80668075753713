import { createContext, useContext, useState } from "react";
import { useAuth } from "src/hooks/useAuth";
import { formatDateString } from "src/utils/formatTime";
import { useShipmentQueries } from "../hooks/useShipmentQueries";

const ShipmentDetailsContext = createContext();

export function ShipmentDetailsProvider(props) {
  const { session } = useAuth();
  const isBackofficeUser = session.permissions.IsBackOffice;
  const isLTLManager = session.permissions.IsLTLManager;

  const [shipment, setShipment] = useState(props.shipment);
  const [quotations, setCarrierQuotations] = useState([]);
  const [selectedQuotation, setSelectedQuotation] = useState();

  const {
    confirmShipment: confirmShipmentRequest,
    getCarrierQuotations: getCarrierQuotationsRequest,
    isConfirmationLoading,
    resetShipmentStatus: resetShipmentStatusRequest,
    isQuotationLoading,
    deleteShipment,
    isDeletionLoading,
    isResettingShipmentLoading,
  } = useShipmentQueries();


  const confirmShipment = (id, loadId = null, quoteId = null, carrier = null) => {
    confirmShipmentRequest({
      shipmentId: shipment._id,
      loadId,
      quoteId,
      carrier,
      callback: setShipmentConfirmed
    });
  };

  const getCarrierQuotations = async () => {
    getCarrierQuotationsRequest({
      shipmentId: shipment._id,
      callback: setCarrierQuotations,
    });
  };

  const resetShipmentStatus = async () => {
    resetShipmentStatusRequest({
      shipmentId: shipment._id,
    });
  };

  function setShipmentConfirmed() {
    setShipment((currentShipment) => {
      return { ...currentShipment, integrated: true };
    });
  }

  const formattedTrackingDetails = () => {
    if (!shipment.tracking) {
      return [];
    }

    return shipment.tracking?.TrackingStatuses.filter(
      (i) => i.Type.toLowerCase() === "web"
    )
      .sort((a, b) => new Date(b.DateTime) - new Date(a.DateTime))
      .map((item) => ({
        date: formatDateString(item.DateTime),
        location: item.City && item.State && `${item.City}, ${item.State}`,
        message: item.CarrierMessage,
      }));
  };

  return (
    <ShipmentDetailsContext.Provider
      value={{
        shipment,
        setShipment,
        tracking: formattedTrackingDetails(),
        resetShipmentStatus,
        isResettingShipmentLoading,
        quotations: quotations,
        setQuotations: setCarrierQuotations,

        selectedQuotation,
        setSelectedQuotation,

        confirmShipment,
        getCarrierQuotations,

        setShipmentConfirmed,
        isConfirmationLoading,
        isQuotationLoading,
        isBackofficeUser,
        isLTLManager,

        deleteShipment,
        isDeletionLoading,
      }}
    >
      {props.children}
    </ShipmentDetailsContext.Provider>
  );
}

export const useShipmentDetails = () => {
  const context = useContext(ShipmentDetailsContext);

  if (!context) {
    throw new Error(
      "useShipmentDetails must be used within a ShipmentDetailsProvider"
    );
  }

  return context;
};