import { Box, Stack, Typography } from "@mui/material";

import { LocationDetails } from "src/components/location-details";

import { fDateString } from "src/utils/formatTime";

import { Location } from "src/models/location";
import { shipmentServices } from "../../constants/services";
import { valueGetter } from "./DetailsDrawer";
import { ServiceField, ServicesDetails } from "./ServicesDetails";

export function LocationsDetails({ shipment }) {
  return (
    <>
      {/* PICKUP & STOP */}
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "space-between",
          width: "100%",
          gap: "0.75rem",
        }}
      >
        {shipment.locations.map(location => {
          <LocationDetails
            locationLabel={location.type === 'pickup' ? 'Origin' : 'Destination'}
            location={new Location().fromShipment(location)}
            type={location.type}
            customFields={
              <Stack>
                <Box
                  sx={{
                    display: {
                      md: "flex",
                      xs: "block",
                    },
                    justifyContent: "space-between",
                    py: "0.25rem",
                    gap: "0.5rem",
                  }}
                >
                  <Typography>Target Early</Typography>
                  <Typography
                    fontWeight={600}
                    textAlign={{
                      md: "right",
                      xs: "left",
                    }}
                  >
                    {valueGetter(
                      fDateString(location.targetEarly)
                    )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: {
                      md: "flex",
                      xs: "block",
                    },
                    justifyContent: "space-between",
                    py: "0.25rem",
                    gap: "0.5rem",
                  }}
                >
                  <Typography>Target Late</Typography>
                  <Typography
                    fontWeight={600}
                    textAlign={{
                      md: "right",
                      xs: "left",
                    }}
                  >
                    {valueGetter(fDateString(location.targetLate))}
                  </Typography>
                </Box>
                <Typography fontWeight={600} mt={1}>
                  Contact Info
                </Typography>
                <Box
                  sx={{
                    display: {
                      md: "flex",
                      xs: "block",
                    },
                    justifyContent: "space-between",
                    py: "0.25rem",
                    gap: "0.5rem",
                  }}
                >
                  <Typography>Contact Name</Typography>

                  <Typography
                    fontWeight={600}
                    textAlign={{
                      md: "right",
                      xs: "left",
                    }}
                  >
                    {valueGetter(location.contacts[0].name)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: {
                      md: "flex",
                      xs: "block",
                    },
                    justifyContent: "space-between",
                    py: "0.25rem",
                    gap: "0.5rem",
                  }}
                >
                  <Typography>Phone</Typography>

                  <Typography
                    fontWeight={600}
                    textAlign={{
                      md: "right",
                      xs: "left",
                    }}
                  >
                    {valueGetter(location.contacts[0].phone)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: {
                      md: "flex",
                      xs: "block",
                    },
                    justifyContent: "space-between",
                    py: "0.25rem",
                    gap: "0.5rem",
                  }}
                >
                  <Typography>E-Mail</Typography>

                  <Typography
                    fontWeight={600}
                    textAlign={{
                      md: "right",
                      xs: "left",
                    }}
                  >
                    {valueGetter(shipment.contacts[0].email)}
                  </Typography>
                </Box>
                <Box py={1}>
                  <Typography fontWeight={600}>Services</Typography>
                  <Box>
                    {shipmentServices
                      .find(
                        (servicePerMode) => servicePerMode.mode == shipment.mode
                      )
                      ?.pickups.map((service, index) => {
                        console.log(service.name);
                        const [category, key] = service.name.split(".");

                        return (
                          <ServiceField
                            service={service}
                            key={service.name + index}
                            checked={
                              shipment?.[category]
                                ? shipment?.[category][key]
                                : false
                            }
                          />
                        );
                      })}
                  </Box>
                </Box>
                <Box py={1}>
                  <Typography fontWeight={600}>Services</Typography>
                  <Box>
                    {shipmentServices
                      .find(
                        (servicePerMode) => servicePerMode.mode == shipment.mode
                      )
                      ?.stops.map((service, index) => {
                        const [category, key] = service.name.split(".");

                        return (
                          <ServiceField
                            service={service}
                            key={service.name + index}
                            checked={
                              shipment?.[category]
                                ? shipment?.[category][key]
                                : false
                            }
                          />
                        );
                      })}
                  </Box>
                </Box>
              </Stack>
            }
          />
        })}
      </Box>
      <ServicesDetails shipment={shipment} />
    </>
  );
}