import config from "src/config.js";
import {
  BaseService
} from "./base.service";

const baseURL = `${config.apiUrl}/orders`;
const validatePoNumbers = `${baseURL}/validate`;
const getPoNumbers = `${baseURL}/po_numbers`;
const getOrderById = `${baseURL}/details`;

export class OrderService extends BaseService {
  async postValidatePoNumbers(datas, signal = null) {
    const body = [];
    if (Array.isArray(datas)) {
      datas?.forEach((data, i) =>
        body.push({
          param: `poNumber.${i}`,
          value: data,
        })
      );
    } else body.push(datas); 

    const response = await fetch(validatePoNumbers, this.header({
      method: "POST",
      body: JSON.stringify(body),
      signal, //for aborting request on unmount
    }));

    const data = await response.json();
    if (response.ok || data.errors) {
      return data;
    }

    throw new Error(data?.errors[0]?.msg || response.statusText);
  }

  async getPoNumbers() {
    const response = await fetch(getPoNumbers, this.header());
    const data = await response.json();

    if (response.ok) {
      return data;
    }

    throw new Error(data?.errors[0]?.msg || response.statusText);
  }

  async getOrdersById(orderId) {
    const uri = new URL(getOrderById);
    uri.searchParams.append('orderIds', orderId)
    const response = await fetch(uri.toString(), this.header());        
    const data = await response.json();

    if (response.ok) {
      return data;
    }

    throw new Error(data?.errors[0]?.msg || response.statusText);
  }

  async getOrders() {
    const response = await fetch(baseURL, this.header());
    const data = await response.json();

    if (response.ok) {
      return data;
    }

    throw new Error(data?.errors[0]?.msg || response.statusText);
  }
}