import { Box } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import PropTypes from "prop-types";
import { useEffect, useState, useRef } from "react";
import DetailRowDrawer from "src/pages/dashboard/components/DetailRowDrawer";
import "./dataTable.css";
import { useDataGridSettings } from "./hooks/useColumnsSettings";
import CustomNoRowsOverlay from "./utils/CustomNoRowsOverlay";
import StyledDataGrid from "./utils/StyledDataGrid";
import ConfirmationDialog from "./validation/ConfirmationDialog";
import { useNavigate, useSearchParams } from "react-router-dom";

export function DataTable({
  detailsDrawerTitle = "Shipment Details",
  detailColumns,
  onEditTapped,
  columns,
  loadData,
  rowUpdateHandler,
  modelKey,
  modelGridSetup,
  getRowId,
  onDrawerClosed,
  ...props
}) {
  const [params] = useSearchParams();
  const [selectedItemId, setSelectedItemId] = useState(
    props?.selectedItemId ? props.selectedItemId : null
  );
  const [selectedItemsIds, setSelectedItemsIds] = useState(
    props?.selectedItemsIds ? props.selectedItemsIds : []
  );
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);

  const gridRef = useGridApiRef();

  useDataGridSettings(gridRef, modelKey, modelGridSetup);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loadData) return;
    if (selectedItemId) {
      const item = loadData.find(
        (item) => (getRowId ? getRowId(item) : item._id) === selectedItemId
      );
      if (item) {
        setSelectedItem(item);
        setShowDrawer(true);
        return;
      }
    }
  }, [loadData, selectedItemId]);

  const handleRowClick = (event) => {
    if (event.field != "__check__") {
      const itemId = event.id;
      setSelectedItemId(itemId);

      navigate(`${itemId}?${params.toString()}`);
      return;
    } else {
      setSelectedItemsIds((prev) => [...prev, event.id]);
    }
  };

  const detailDrawerVisibilityHandler = (isVisible) => {
    if (!isVisible) {
      setSelectedItemId(null);
      setSelectedItemsIds([]);
      setShowDrawer(false);
      if (onDrawerClosed) onDrawerClosed();
    }
  };

  const handleFilterModelChange = (model) => {
    if (!model) return null;
    const formattedModel = { ...model };

    Object.keys(model).forEach((field) => {
      const column = columns.find((col) => col.field === field);
      if (column?.type === "date") {
        formattedModel[field].value = model[field].value?.toLocaleDateString();
      }
    });

    return formattedModel;
  };

  const DrawerComponent = props.customDrawerComponent
    ? props.customDrawerComponent
    : DetailRowDrawer;

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {/* {renderConfirmDialog()} */}
      <StyledDataGrid
        rows={loadData}
        columns={columns}
        loading={props.loading}

        pagination
        paginationMode={props.paginationMode ?? "client"}
        estimatedRowCount={props.estimatedRowCount}
        rowCount={props.rowCount}

        filterMode={props.filterMode ?? "client"}
        paginationModel={props.paginationModel}
        onPaginationModelChange={props.onPaginationModelChange}

        autoHeight
        density="compact"
        getRowId={(row) => (getRowId ? getRowId(row) : row._id)}

        // DEFAULT ON SINGLE SELECTION - OPEN DRAWER
        disableMultipleRowSelection={!!props?.disableMultipleRowSelection}
        checkboxSelection={!!props.disableMultipleRowSelection}
        onCellClick={handleRowClick}

        initialState={{
          columns: {
            columnVisibilityModel: {},
          },
          pagination: { rowCount: -1 },
          paginationModel: props.paginationModel
            ? props.paginationModel
            : { pageSize: 15, page: 0 },
        }}
        pageSizeOptions={
          props.pageSizeOptions ? props.pageSizeOptions : [15, 20, 50, 100]
        }
        // onFilterModelChange={props.onFilterModelChange ?? handleFilterModelChange}
        slots={{ ...props.slots, noRowsOverlay: CustomNoRowsOverlay }}
        slotProps={{
          ...(props?.slotProps || []),
          selectedItemsIds: [selectedItemId, ...selectedItemsIds],
        }}
        onProcessRowUpdateError={(error) =>
          console.error("Error processing row update:", error)
        }
        {...props}
        apiRef={gridRef}

      />
      {showDrawer && (
        <DrawerComponent
          title={detailsDrawerTitle}
          onEditTapped={onEditTapped}
          onVisibilityChanged={detailDrawerVisibilityHandler}
          selectedItem={selectedItem}
          columns={detailColumns}
          modelRoute={modelKey}
          isClosedShipment={props.isClosedShipment}
        />
      )}
    </Box>
  );
}

DataTable.propTypes = {
  loadData: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    })
  ).isRequired,
  detailsDrawerTitle: PropTypes.string,
  detailColumns: PropTypes.array,
  onEditTapped: PropTypes.func,
  columns: PropTypes.array.isRequired,
  rowUpdateHandler: PropTypes.func,
  modelKey: PropTypes.string,
  modelGridSetup: PropTypes.object,
  getRowId: PropTypes.func,
  apiRef: PropTypes.object, // Add PropTypes for apiRef
  // Add more PropTypes as needed
};
