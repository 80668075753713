import { Box, Dialog, DialogContent, Modal } from "@mui/material";
import { LocationFields } from "./form/LocationFields";

export const LocationDialog = ({ isOpen, onClose, onUpdated, itemIndex, isEdditing }) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth
            maxWidth="md"
            sx={{
                backdropFilter: "blur(5px) sepia(5%)",
            }}
            PaperProps={{ sx: { borderRadius: '16px' } }}
        >
            <DialogContent
                style={{
                    overflow: "hidden",
                    padding: 0,

                }}
            >
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    height={"100%"}
                    alignItems={"center"}
                >
                    <Box
                        display={"flex"}
                        flex={"column"}
                        borderRadius={"8px"}
                        bgcolor={"#f5f5f5"}
                        width={"100%"}
                        maxHeight={"95vh"}
                        overflow={"auto"}
                        padding={"30px"}
                    >
                        <LocationFields
                            itemIndex={itemIndex}
                            onUpdated={onUpdated}
                            isEdditing={isEdditing}
                        />
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}