import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { FormAccordion } from "src/components/form/FormAccordion";
import { valueGetter } from "../details-drawer/DetailsDrawer";
import { DeleteForeverOutlined, Edit, ExpandMoreOutlined } from "@mui/icons-material";
import React from "react";
import { fDateString } from "src/utils/formatTime";

export const LocationListItem = ({ location, index, onEditTapped, onRemoveTapped, title, disableActions }) => {
  return (
    <FormAccordion
      defaultExpanded={false}
      title={title}
      fontWeight={800}
      fontSize={18}
      header={
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography>{title}</Typography>

          {disableActions ? <ExpandMoreOutlined /> : (<Box>
            <Button variant="outlined" onClick={(event) => {
              event.stopPropagation();
              onEditTapped(index);
            }}>
              <Edit />
            </Button>
            <Button color="error"
              onClick={(event) => {
                event.stopPropagation();
                onRemoveTapped(index);
              }}
            ><DeleteForeverOutlined /></Button>
          </Box>)}
        </Box>
      }
    >
      <Stack direction={"column"} gap={1}>
        <Box
          sx={{
            display: {
              md: "flex",
            },
            gap: "0.5rem",
          }}
        >
          <Typography fontWeight={600}>Address 1:</Typography>
          <Typography>{valueGetter(location?.address1)}</Typography>
        </Box>
        <Box
          sx={{
            display: {
              md: "flex",
            },
            gap: "0.5rem",
          }}
        >
          <Typography fontWeight={600}>Address 2:</Typography>
          <Typography>{valueGetter(location?.address2)}</Typography>
        </Box>
        <Box display={"flex"} gap={"0.5rem"} justifyContent={"space-between"}>
          <Box display={"flex"} gap={"0.5rem"}>
            <Typography fontWeight={600}>City:</Typography>
            <Typography>{valueGetter(location?.city)}</Typography>
          </Box>
          <Box display={"flex"} gap={"0.5rem"}>
            <Typography fontWeight={600}>State:</Typography>
            <Typography>{valueGetter(location?.state)}</Typography>
          </Box>
          <Box display={"flex"} gap={"0.5rem"}>
            <Typography fontWeight={600}>ZIP:</Typography>
            <Typography>{valueGetter(location?.zip)}</Typography>
          </Box>
          <Box display={"flex"} gap={"0.5rem"}>
            <Typography fontWeight={600}>Country:</Typography>
            <Typography>{valueGetter(location?.country)}</Typography>
          </Box>
        </Box>
        <Divider />
        <Box marginY={"0.8rem"}>
          <Typography fontWeight={600} fontSize={18} marginBottom={"0.5rem"}>
            Target
          </Typography>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box display={"flex"} gap={"0.5rem"}>
              <Typography fontWeight={600}>Early:</Typography>
              <Typography>{fDateString(location.targetEarly)}</Typography>
            </Box>
            <Box display={"flex"} gap={"0.5rem"}>
              <Typography fontWeight={600}>Late:</Typography>
              <Typography>{fDateString(location.targetLate)}</Typography>
            </Box>
          </Box>
          <Box display={"flex"} marginTop={"0.5rem"} gap={"0.5rem"}>
            <Typography fontWeight={600}>Timezone:</Typography>
            <Typography fontSize={14} marginTop={0.3}>
              {valueGetter(location?.timezone)}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box marginY={"0.8rem"}>
          <Typography fontWeight={600} fontSize={18} marginBottom={"0.5rem"}>
            Contact
          </Typography>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box display={"flex"} gap={"0.5rem"}>
              <Typography fontWeight={600}>Name:</Typography>
              <Typography>{location?.contacts && valueGetter(location?.contacts[0]?.name)}</Typography>
            </Box>
            <Box display={"flex"} gap={"0.5rem"}>
              <Typography fontWeight={600}>Email:</Typography>
              <Typography>{location?.contacts && valueGetter(location?.contacts[0]?.email)}</Typography>

            </Box>
            <Box display={"flex"} gap={"0.5rem"}>
              <Typography fontWeight={600}>Phone:</Typography>
              <Typography>{location?.contacts && valueGetter(location?.contacts[0]?.phone)}</Typography>
            </Box>
          </Box>
        </Box>
      </Stack>
    </FormAccordion>
  );
};
