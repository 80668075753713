import { useQuery } from "react-query";
import { CarrierService } from "src/api/carrier.service";
import { useAuth } from "src/hooks/useAuth";

export const useCarrierQueries = () => {
    const { session } = useAuth();
    const service = new CarrierService(session.token, session.tenant._id);

    const {
        isLoading: isCarriersLoading,
        data: carriers,
        refetch: getCarriers,
    } = useQuery({
        queryKey: ["carriers"],
        queryFn: () => {
            return service.getCarriers();
        },
        enabled: false,
    });

    return {
        isCarriersLoading,
        carriers,
        getCarriers,
    }
}