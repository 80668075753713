import {
  Location
} from "./location";

const cityStateZipREGEX = /^(.*),\s*([A-Z]{2})\s*([\d-]+)$/;

export class Order {
  static responseToShipment(orderResponse, ignoreShipmentNumber = false) {
    const [, city, state, zip] =
    orderResponse.CITY_ST_ZIP.match(cityStateZipREGEX);

    function getStop() {
      if (orderResponse.stop) {
        return Location.toShipment(orderResponse.stop, "delivery");
      } else {
        return {
          type: 'delivery',
          name: orderResponse.SHIP_TO_LOCATION ?? "",
          address1: orderResponse.ADDRESS1 ?? "",
          address2: orderResponse.ADDRESS2 ?? "",
          city: city,
          state: state,
          zip: zip,
        };
      }
    }

    function getPickup() {
      if (!orderResponse.pickup) {
        return {
          type: 'pickup',
          name: orderResponse.VENDOR_NM ?? "",
        };
      }

      const isArray = Array.isArray(orderResponse.pickup);
      if (isArray && orderResponse.pickup.length) {
        return {
          ...Location.toShipment(orderResponse.pickup[0], "pickup"),
        };
      }

      return {
        ...Location.toShipment(orderResponse.pickup, "pickup"),
      };
    }

    return {
      shipmentNumber: !ignoreShipmentNumber ? [orderResponse.SHIPMENT_NUM ?? ""] : [],
      locations: [
        getPickup(),
        getStop(),
      ],
    };
  }
}