export const LocationColums = [
    {
        field: 'name',
        headerName: 'Name',
        minWidth: 250,
    },
    {
        field: 'addr1',
        headerName: 'Address 1',
        minWidth: 250,
    },
    {
        field: 'addr2',
        headerName: 'Address 2',
        minWidth: 200,
    },
    {
        field: 'city',
        headerName: 'City',
        minWidth: 150,
    },
    {
        field: 'state',
        headerName: 'State',
        minWidth: 150,
    },
    {
        field: 'ctry',
        headerName: 'Country',
        minWidth: 150,
    },
    {
        field: 'zip',
        headerName: 'ZIP',
        minWidth: 150,
    }
]

export const LocationDetailComuns = [
    {
        key: 'name',
        label: 'Name',
    },
    {
        key: 'addr1',
        label: 'Address 1',
    },
    {
        key: 'addr2',
        label: 'Address 2'
    },
    {
        key: 'city',
        label: 'City'
    },
    {
        key: 'state',
        label: 'State'
    },
    {
        key: 'zip',
        label: 'ZIP'
    }
]