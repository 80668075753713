import { Autocomplete, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export function TagsField() {
  const { control } = useFormContext();

  return (
    <Controller
      name={'refOrder'}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value } = field;
        console.log(value);
        return (
          <Autocomplete
            {...field}
            multiple
            limitTags={3}
            freeSolo
            fullWidth
            autoComplete
            includeInputInList
            options={[]}
            value={value ?? []}
            onChange={(event, newValue) => {
              onChange(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Tags"
                fullWidth
                label={`Tags`}
                {...error}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        );
      }}
    />
  );
}
