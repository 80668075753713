import { FormControlLabel, Grid, MenuItem, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormInput } from "src/components/form/FormInput";
import { hazmatClasses } from "../../constants/hazmat";

export function HazmatFieldsWrapper({ packageIndex }) {
  const { watch, getValues, control } = useFormContext();

  const [isHazmatCargo, setHazmatCargo] = useState(
    getValues("services.hazardousCargoCharge") &&
    getValues(`items.${packageIndex}.isHazmat`)
  );

  const [isHazmatPackage, setHazmatPackage] = useState(
    getValues(`items.${packageIndex}.isHazmat`)
  );

  useEffect(() => {
    setHazmatCargo(getValues("services.hazardousCargoCharge"));
  }, [watch(`services.hazardousCargoCharge`)]);

  useEffect(() => {
    setHazmatPackage(getValues(`items.${packageIndex}.isHazmat`));
  }, [watch(`items.${packageIndex}.isHazmat`)]);

  return !isHazmatCargo ? <></> : <>
    <Controller
      name={`items.${packageIndex}.isHazmat`}
      control={control}
      defaultValue={false}
      render={({ field }) => (
        <FormControlLabel
          sx={{ width: "25%", margin: 0, py: 1 }}
          control={<Switch {...field} checked={!!field.value} />}
          label={"Is Hazmat package?"}
        />
      )}
    />
    {isHazmatPackage && (
      <Grid container rowGap={2}>
        <Grid item xs={12}>
          <FormInput
            name={`items.${packageIndex}.hazardous.properShippingName`}
            label="Proper Shipping Name"
            fullWidth
            size="normal"
          />
        </Grid>
        <Grid item container xs={12} rowGap={2}>
          <Grid item md={3} xs={12}>
            <FormInput
              name={`items.${packageIndex}.hazardous.unNumber`}
              label="UN Number"
              type="number"
              fullWidth
              size="normal"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <FormInput
              name={`items.${packageIndex}.hazardous.group`}
              label="Group"
              fullWidth
              size="normal"
              select
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="n/a">N/A</MenuItem>
            </FormInput>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormInput
              name={`items.${packageIndex}.hazardous.flashpointTemp`}
              label="Flashpoint Temp"
              fullWidth
              size="normal"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <FormInput
              name={`items.${packageIndex}.hazardous.class`}
              label="Class"
              type="number"
              fullWidth
              size="normal"
              select
            >
              {hazmatClasses.map((value) => (
                <MenuItem value={value} key={value}>
                  {value}
                </MenuItem>
              ))}
            </FormInput>
          </Grid>
        </Grid>
        <Grid item container md={12} rowGap={2}>
          <Grid item md={6} xs={12}>
            <FormInput
              name={`items.${packageIndex}.hazardous.contactName`}
              label="Emergency Contact Name"
              fullWidth
              size="normal"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormInput
              name={`items.${packageIndex}.hazardous.contactPhone`}
              label="Emergency Contact Phone"
              fullWidth
              size="normal"
            />
          </Grid>
        </Grid>
      </Grid>
    )}
  </>
}
