import { Box, Button, Card, CardContent, CardHeader, MenuItem, Stack, Typography } from "@mui/material";
import { LocationFormProvider } from "../context/LocationFormContext";
import { FormInput } from "src/components/form/FormInput";
import { usaStates } from "src/pages/warehouses/constants/usaStates";
import { LoadingButton } from "@mui/lab";
import { useLocationFormContext } from "../hooks/useLocationFormContext";
import { Clear, Watch } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { useLocationQueries } from "../hooks/useLocationQueries";
import { OperationHours } from "./OperationHoursFields";
import { timezones } from "src/utils/timezones";
import { useAuth } from "src/hooks/useAuth";
import { TagsField } from "../components/TagsField";
import { InputStateField } from "../components/StateField";
import { countries } from "src/pages/shipments/constants/countries";
import { useTenant } from "src/hooks/useTenant";
import { Container, Draggable } from "react-smooth-dnd"
import { FormInputMask } from "src/components/form/FormInputMask";
import { useFormContext } from "react-hook-form";

export function LocationForm({
  useFormParams,
  onSave,
  reset,
  ...props
}) {
  const isSubmissionLoading = props.isSubmissionLoading;
  const refId = useFormParams?.defaultValues?.refId;
  const [refType, setRefType] = useState(useFormParams?.defaultValues?.refType);
  const { references, getReferences } = useLocationQueries();
  const { tenant } = useTenant();
  const isVisible = useMemo(() => {
    return tenant.code === 'COX';
  }, [tenant])

  const { session } = useAuth()

  useEffect(() => {
    getReferences(refType);
  }, [refType])

  useEffect(() => {
    getReferences(refType ?? useFormParams?.defaultValues?.refType);
  }, [useFormParams?.defaultValues])

  return (
    <LocationFormProvider
      useFormParams={useFormParams}
      onSave={onSave}
      reset={reset}
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
    >
      <Card
        variant="outlined"
        sx={{
          py: "1.5rem",
          px: "1rem",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem"
        }}
      >
        <CardHeader
          title={"Basic Info"}
          sx={{ "&.MuiCardHeader-root": { p: 0 } }}
        />
        <CardContent
          sx={{
            "&.MuiCardContent-root": { p: 0, py: "1rem" },
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "0.75rem",
          }}
        >
          <Stack spacing={2}>
            <FormInput
              name={'name'}
              label="Name"
              required
              type="text"
              sx={{ flexGrow: 1 }}
            />
            <FormInput
              name={'addr1'}
              label="Address 1"
              required
              type="text"
              sx={{ flexGrow: 1 }}
            />
            <FormInput
              name={'addr2'}
              label="Address 2"
              type="text"
              sx={{ flexGrow: 1 }}
            />
            <Box sx={{ display: "flex", gap: 2 }}>
              <FormInput
                name={'city'}
                label="City"
                type="text"
                required
                sx={{ flexGrow: 1 }}
              />
              <InputStateField />
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <FormInput
                name={'zip'}
                label="Zip Code"
                required
                sx={{ flexGrow: 1 }}
              />
              <FormInput
                name={`ctry`}
                label="Country"
                select
                required
                sx={{ flexGrow: 1 }}
                defaultValue={countries[0].acronym}
              >
                {countries.map((ctry) => {
                  return (
                    <MenuItem key={ctry.acronym} value={ctry.acronym}>
                      {ctry.name}
                    </MenuItem>
                  );
                })}
              </FormInput>
            </Box>

            <FormInput
              sx={{ flex: 1 }}
              name="timezone"
              label="Timezone"
              defaultValue={
                timezones.find((t) => t.name == "America/Los_Angeles").name
              }
              select
              required
              disabled={!session.permissions.IsAdmin}
            >
              {timezones.map((tz) => {
                return (
                  <MenuItem key={tz.name} value={tz.name}>
                    {tz.rawFormat}
                  </MenuItem>
                );
              })}
            </FormInput>

            <TagsField />
            {isVisible &&
              <FormInput
                sx={{
                  flexGrow: 1,
                }}
                name="refType"
                label="Reference By"
                onChange={(event) => { setRefType(event.target.value) }}
                select
              >
                {['Warehouse', 'Vendor'].map((value) => {
                  return (
                    <MenuItem
                      key={value}
                      value={value}
                    >
                      {value}
                    </MenuItem>
                  );
                })}
              </FormInput>
            }
            {isVisible &&
              <FormInput
                sx={{
                  flexGrow: 1
                }}
                name="refId"
                label="Reference"
                defaultValue={refId}
                select
              >
                {references?.map((reference) => {
                  return (
                    <MenuItem
                      key={reference._id}
                      value={reference._id}
                    >
                      {reference.name}
                    </MenuItem>
                  );
                })}
              </FormInput>
            }

          </Stack>
        </CardContent>
      </Card>

      <OperationHours />
      <Contacts tenant={tenant} />

      <LoadingButton
        variant="contained"
        fullWidth
        type="submit"
        loading={isSubmissionLoading}
        sx={{ py: "0.8rem", mt: "1rem" }}
      >
        Save Location
      </LoadingButton>
    </LocationFormProvider>
  );
}

function Contacts({ tenant }) {
  const { fieldsContact, removeContact, appendContact, moveContact } =
    useLocationFormContext();

  const { getValues, watch } = useFormContext();

  const countrie = useMemo(() => {
    const country = getValues('ctry');
    if (country === "CA" || country === "MX" || country === "USA")
      return country
    else
      return '';
  }, [watch('ctry')]);

  const onDragEnd = ({ removedIndex, addedIndex }) => {
    moveContact(removedIndex, addedIndex);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography component="h4">Contacts</Typography>
      <Container
        dragHandleSelector=".drag-handle"
        lockAxis="y"
        onDrop={onDragEnd}
      >
        {fieldsContact.map((contact, index) => {
          return (
            <Draggable key={contact.id}>
              <div className="drag-handle" style={{ margin: 5 }}>
                <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                  <FormInput
                    name={`contacts[${index}].name`}
                    label="Name"
                    required
                    type="text"
                    sx={{ flexGrow: 1 }}
                  />
                  <FormInputMask
                    name={`contacts[${index}].phone`}
                    required
                    selectCountry={countrie}
                    sx={{ flexGrow: 1 }}
                  />
                  <FormInput
                    name={`contacts[${index}].email`}
                    label="Email"
                    required={tenant.code !== 'CLI'}
                    type="email"
                    sx={{ flexGrow: 1 }}
                  />
                  <Button
                    sx={{ ml: 0, mr: 0 }}
                    onClick={() => removeContact(index)}
                  >
                    <Clear />
                  </Button>
                </Box>
              </div>
            </Draggable>
          );
        })}
      </Container>
      <Button
        size="small"
        variant="text"
        align="left"
        onClick={() => appendContact({ name: "", phone: "", email: "" })}
      >
        + Add Contact
      </Button>
    </Box>
  );
}
