import { Box } from "@mui/material";
import { useActionables } from "./actionables";

export function ShipmentActions() {
  const actionables = useActionables();

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {actionables
        .filter((action) => action.isShown)
        .map(({ Actionable, key }) => (
          <Actionable key={key} />
        ))}
    </Box>
  );
}
